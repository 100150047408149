const apiEndPoints = {
  itemsData: "/cl/apis/v1/itemsData",
  ispList: "/cl/apis/v1/ispList",
  listData: "/cl/apis/v1/list",
  segment: "/cl/apis/v1/segment",
  offer: "/cl/apis/v1/getOffers",
  emailServiceProvider: "/cl/apis/v1/emailServiceProvider",
  getTemplate: "/cl/apis/v1/getTemplate",
  emailServiceProviderAccounts: "/cl/apis/v1/emailServiceProviderAccounts",
  getDomainByESP: "/cl/apis/v1/getDomainByESP",
  getCampaignJourneyList: "/cl/apis/v1/campaignJourney",
  getCallApi: "/cl/apis/v1/callapi",
  getJourneyStats: "/cl/apis/v1/get-journey-stats",
  getAllJourneyStats: "/cl/apis/v1/all-journey-stats",
  getAllEmailStats: "/cl/apis/v1/all-email-stats",
  getEventOcuurStats: "/cl/apis/v1/eventOccurs",
  getSuppresion: "/cl/apis/v1/getSuppression",
  getHeaderNames: "/cl/apis/v1/getHeaderNames",
  getSendTestEmail: "/cl/apis/v1/sendTestEmail ",
  getCopyData: "/cl/apis/v1/campaignJourneyCopy",
  getRunData: "/cl/apis/v1/startJourney",
  getPausedData: "/cl/apis/v1/campaignJourneyPaused/",
  campaignJourneyStart: "/cl/apis/v1/campaignJourneyStart/",
  getStopData: "/cl/apis/v1/campaignJourneyStop",
  getDeleteData: "/cl/apis/v1/campaignJourney",
  allNetworkDetails: "/cl/apis/v1/allNetworkDetails",
  updateNetworkActiveStatus: "/cl/apis/v1/updateNetworkActiveStatus",
  getAllCountries: "/cl/apis/v1/getAllCountries",
  addNetworkDetails: "/cl/apis/v1/addNetworkDetails",
  updateNetworkDetails: "/cl/apis/v1/updateNetworkDetails",
  allOfferDetails: "/cl/apis/v1/allOfferDetails",
  updateOfferActiveStatus: "/cl/apis/v1/updateOfferActiveStatus",
  getAllNetworkNames: "/cl/apis/v1/getAllNetworkNames",
  getAllVerticalNames: "/cl/apis/v1/getAllVerticalNames",
  getAllSubVerticalNames: "/cl/apis/v1/getAllSubVerticalNames",
  getAllPaymentTypes: "/cl/apis/v1/getAllPaymentTypes",
  addOfferDetails: "/cl/apis/v1/addOfferDetails",
  updateOfferDetails: "/cl/apis/v1/updateOfferDetails",
  allTemplateDetails: "/cl/apis/v1/allTemplateDetails",
  updateTemplateActiveStatus: "/cl/apis/v1/updateTemplateActiveStatus",
  emailTags: "/cl/apis/v1/emailTags",
  addTemplateDetails: "/cl/apis/v1/addTemplateDetails",
  updateTemplateDetails: "/cl/apis/v1/updateTemplateDetails",
  allHeaderDetails: "/cl/apis/v1/allHeaderDetails",
  updateHeaderActiveStatus: "/cl/apis/v1/updateHeaderActiveStatus",
  addHeaderDetails: "/cl/apis/v1/addHeaderDetails",
  updateHeaderDetails: "/cl/apis/v1/updateHeaderDetails",
  allFooterDetails: "/cl/apis/v1/allFooterDetails",
  updateFooterActiveStatus: "/cl/apis/v1/updateFooterActiveStatus",
  addFooterDetails: "/cl/apis/v1/addFooterDetails",
  updateFooterDetails: "/cl/apis/v1/updateFooterDetails",

  //// data management end points
  getAllSegmentFields: "dm/apis/v1/getAllSegmentFields",
  addList: "/dm/apis/v1/addList",
  allListDetails: "/dm/apis/v1/allListDetails",
  getRecordsById: "/dm/apis/v1/getRecordsById",
  updateListName: "/dm/apis/v1/updateListName",
  updateDataToList: "/dm/apis/v1/updateDataToList",
  deleteList: "/dm/apis/v1/deleteList",
  searchRecordByEmails: "/dm/apis/v1/searchRecordByEmails",
  addSuppressionRecord: "/dm/apis/v1/addSuppressionRecord",
  addGlobalSuppression: "/dm/apis/v1/addGlobalSuppression",
  updateSuppressionListName: "/dm/apis/v1/updateSuppressionListName",
  allSuppressionListDetails: "/dm/apis/v1/allSuppressionListDetails",
  deleteSuppressionList: "/dm/apis/v1/deleteSuppressionList",
  addDataToSuppressionList: "/dm/apis/v1/addDataToSuppressionList",
  dataTypes: "/dm/apis/v1/dataTypes",
  listTypes: "/dm/apis/v1/listTypes",
  suppressoinList: "/dm/apis/v1/suppressoinList",
  checkSuppressionListNameExistence:
    "/dm/apis/v1/checkSuppressionListNameExistence",
  checkListNameExistence: "/dm/apis/v1/checkListNameExistence",
  removeDataOptions: "/dm/apis/v1/removeDataOptions",
  deleteListRecords: "/dm/apis/v1/deleteListRecords",
  deleteSuppressionListRecords: "/dm/apis/v1/deleteSuppressionListRecords",
  getTotalRecordsCount: "/dm/apis/v1/getTotalRecordsCount",
  getOperators: "/dm/apis/v1/getOperators",
  emailListRecordsByListId: "/cl/apis/v1/emailListRecordsByListId",

  // segnment
  getEngagementData: "/dm/apis/v1/getSegmentDetails/emailengagement",
  deleteSegnmentRecords: "/dm/apis/v1/deleteSegmentDetails",
  engagementCount: "/dm/apis/v1/getSegmentTotalRecordsCount",
  copySegnment: "/dm/apis/v1/copyAndCreateSegment",
  getFlushData: "/dm/apis/v1/getSegmentDetails/emailflush",
  flushSegnment: "/dm/apis/v1/getFlushingSegmentNow",
  flushCount: "/dm/apis/v1/getFlushingSegmentTotalRecordsCount",
  addFlushSchedular: "/dm/apis/v1/saveSegmentEmailFlush",
  updateFlushSchedular: "/dm/apis/v1/getSegmentFlushById",
  timeZone: "/cl/apis/v1/timeZone",
  getRelativesBehaviours: "/dm/apis/v1/getRelatives",
  getSlots: "/dm/apis/v1/getSlots",
  getSegnmentCategory: "/dm/apis/v1/getSegmentCategory",
  getSegmentField: "/dm/apis/v1/getAllSegmentFields",
  getOperators: "/dm/apis/v1/getOperators",
  getBehaviours: "/dm/apis/v1/getBehaviours",
  getAbsolutesBehaviours: "/dm/apis/v1/getAbsolutes",
  getRelativesBehaviours: "/dm/apis/v1/getRelatives",
  updateSegnment: "/dm/apis/v1/updateSegmentDetails",
  addSegnmentDetail: "/dm/apis/v1/addSegmentDetails",
  numberOfTimes: "/dm/apis/v1/getNumberOfTimes",

  /// pool
  pool: "/cl/apis/v1/pool",
  poolCreate: "/cl/apis/v1/poolCreate",
  poolByPoolType: "/cl/apis/v1/poolByPoolType/",
  poolType: "/cl/apis/v1/poolType/",
  updatepoolActiveStatusL: "/cl/apis/v1/updatepoolActiveStatus",
  getTemplate: "/cl/apis/v1/getTemplate",
  emailServiceProvider: "/cl/apis/v1/emailServiceProvider",
  getHeaderIdAndName: "/cl/apis/v1/getHeaderIdAndName",
  //journeyName
  JourneyNameExistOrNot: "/cl/apis/v1/JourneyNameExistOrNot",
  //network
  networkPortalList: "/cl/apis/v1/networkPortalList",
  EverFlowNetworks: "cl/apis/v1/EverFlowNetworks",
  EverFlowNetworksOffersForm: "cl/apis/v1/EverFlowAdvertisers",
  // EverFlowOffers: "cl/apis/v1/EverFlowOffers",
  EverFlowOffers: "cl/apis/v1/EverFlowOffers",

  EverFlowAffiliates:"cl/apis/v1/EverFlowAffiliates",

  // admin console esp
  addEspAccount: "/dm/apis/v1/addEspAccount",
  getAllEspProviderAccount: "/dm/apis/v1/getAllEspProviderAccount",
  // admin consle domain account
  domainAccountsDetails: "/dm/apis/v1/domainAccountsDetails",
  updateDomainAccountActiveStatus:
    "/dm/apis/v1/updateDomainAccountActiveStatus",
  getDomainServicesNames: "/dm/apis/v1/getDomainServicesNames",
  addDomainAccountsDetails: "/dm/apis/v1/addDomainAccountsDetails",
  updateDomainAccountsDetails: "/dm/apis/v1/updateDomainAccountsDetails",
  addDomainDetails: "/dm/apis/v1/addDomainDetails",
  domainDetails: "/dm/apis/v1/domainDetails",
  updateDomainActiveStatus: "/dm/apis/v1/updateDomainActiveStatus",
  updateDomainDetails: "/dm/apis/v1/updateDomainDetails",

  ///// global journey setting
  allespinfo: "/dm/apis/v1/allespinfo",
  getIsps: "/dm/apis/v1/getIsps",
  changeEspIspStatus: "/dm/apis/v1/changeEspIspStatus",
  addCap: "/dm/apis/v1/addCap",
  updateCapById: "/dm/apis/v1/updateCapById",
  userlevelcap: "/dm/apis/v1/userlevelcap",
  saveuserlevelcap: "/dm/apis/v1/saveuserlevelcap",
  getAllBounce: "/dm/apis/v1/getAllBounce",

  //// 27 feb
  bounceBehaviourDropDown: "/dm/apis/v1/bounceBehaviourDropDown",
  createBounce: "/dm/apis/v1/createBounce",
  updateBounceById: "/dm/apis/v1/updateBounceById",
  bounceStatusChange: "/dm/apis/v1/bounceStatusChange",

  //// 28 feb
  periodValuesDropDown: "/dm/apis/v1/periodValuesDropDown",
  saveEspCommitment: "/dm/apis/v1/saveEspCommitment",
  EspCommitment: "/dm/apis/v1/EspCommitment",
  updateEspCommitment: "/dm/apis/v1/EspCommitment",
  EspCommitmentStatus: "/dm/apis/v1/EspCommitmentStatus",
  notificationSettings: "/dm/apis/v1/notificationSettings",
  saveNotificationDetails: "/dm/apis/v1/saveNotificationDetails",
  getalldisaster: "/dm/apis/v1/getalldisaster",
  disasterdropdown: "/dm/apis/v1/disasterdropdown",
  actiontype: "/dm/apis/v1/actiontype",
  updatedisasterbyid: "/dm/apis/v1/updatedisasterbyid",
  changestatus: "/dm/apis/v1/changestatus",
  // quick campaign
  createQuickJourney: "cl/apis/v1/createQuickJourney",
  allQuickJourney: "cl/apis/v1/allQuickJourney",
  updateQuickJourney: "cl/apis/v1/updateQuickJourney",
  // image hosting
  imageHosting: "dm/apis/v1/imageHosting",
  

  // domain
  domainHistoricalData: "dm/apis/v1/domainHistoricalData",
  // ip historical data
  getallIp: "dm/apis/v1/domainIPHistoricalData/getallIp",
  addIp: "dm/apis/v1/domainIPHistoricalData/addIp",
  updateIp: "dm/apis/v1/domainIPHistoricalData/updateIp",

  /////// creative
  addNetwork: "dm/apis/v1/creative-compliance/network/add",
  getNetworkDetails: "dm/apis/v1/creative-compliance/network/getdetails",
  updateNetwork: "dm/apis/v1/creative-compliance/network/update",
  getNetworkList: "dm/apis/v1/creative-compliance/network/getlist",
  textChecker: "dm/apis/v1/creative-compliance/checker",
  networkComplainceDetails: "dm/apis/v1/creative-compliance/get-details",
  searchAssest:"dm/apis/v1/creative-compliance/search-network-list",
  getNetworkName:"dm/apis/v1/creative-compliance/get-network-list",
  addNetworkComplaince:"dm/apis/v1/creative-compliance/add",
  editNetworkComplaince:"dm/apis/v1/creative-compliance/edit/",
  getSuggetionList:"dm/apis/v1/creative-compliance/get-suggetion-list",

//datepikerJourneyStats
quickJourney: "cl/apis/v1/allQuickJourney",
campaignJourney: "cl/apis/v1/CampaignJourneyData",


};
export default apiEndPoints;
