export const setListTable = (payload) => ({
  type: "SET_LIST_TABLE",
  payload,
});
export const setSupressionTable = (payload) => ({
  type: "SET_SUPRESSION_TABLE",
  payload,
});
export const setEmailEngagementTable = (payload) => ({
  type: "SET_EMAIL_ENGAGEMENT_TABLE",
  payload,
});
export const setEmailFlushTable = (payload) => ({
  type: "SET_EMAIL_FLUSH_TABLE",
  payload,
});
